import { Auth } from 'aws-amplify';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

export const querySubscription = async () => {
    return Auth.currentSession()
        .then(res => {
            console.log("token", res.getAccessToken().jwtToken);
            const token = res.getAccessToken().jwtToken;
            const configuration = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            return axios.get(`${url}/subscription`, configuration);
        });
    
};