import orderPropType from "../../proptypes/OrderPropType";
import StepDisplay from "../StepDisplay";
import PropTypes from 'prop-types';
import dayjs from "dayjs";

CreateSKULabelsStep.propTypes = {
    order: orderPropType,
    setOrder: PropTypes.func.isRequired,
};

export default function CreateSKULabelsStep ({order, setOrder}) {
    const stepOrder = 1;
    const handleComplete = () => {
        setOrder({
            ...order,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Create SKU labels"}
        description={"Visit Amazon Seller Central, go to \"Mannage All Inventory\" section and on the dropdown for the product, click on \"Print item labels\""}
        showSkipButton
        showContinueButton
        continueButtonText={"Complete"}
        onComplete={handleComplete}
        onSkip={handleComplete}
    >
        <p className="sku-labels-info">{`${order.sku}: ${order.unitsAmount} units`}</p>
    </StepDisplay>;
}