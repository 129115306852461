import { Outlet, NavLink, useLocation, Link } from "react-router-dom";
import HorizontalLogo from "../logo-horizontal.png";
import { IoBoat, IoHome, IoCalendar } from "react-icons/io5";
import { MdSettings } from "react-icons/md";
import { capitalizeFirst } from "../utils/utils";
import { Divider, Dropdown, Space } from 'antd';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { DownOutlined } from '@ant-design/icons';
import { BsDiscord } from "react-icons/bs";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";


const menus = [
    {
        id: "orders",
        icon: <IoHome/>
    },
    {
        id: "imports",
        icon: <IoBoat/>
    },
    {
        id: "calendar",
        icon: <IoCalendar/>
    },
];

export default function Root() {

    const location = useLocation();
    const [username, setUsername] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                console.log(user);
                setUsername(user.attributes.email);
            });
    }, []);

    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location]);

    const dropdownItems = [
        {
            key: '0',
            label: <a type="text" target="#" href="https://discord.gg/yGAevWmYqY">Discord server</a>,
            icon: <BsDiscord/>
        },
        {
            key: '1',
            label: <a type="text" onClick={() => navigate("/settings")}>Settings</a>,
            icon: <MdSettings/>
        },
        {
            type: 'divider',
        },
        {
            key: '2',
            label: <a type="text" onClick={() => Auth.signOut()}>Log out</a>,
            icon: <HiOutlineLogout/>
        }
    ];

    return (
        <>
            <div id="topbar">
                <div id="logo-section">
                    <Link to="/"><img src={HorizontalLogo} alt="Amazops"></img></Link>
                </div>
                <div id="right-section">
                    <Dropdown
                        arrow
                        className="topbar-menu-item"
                        menu={{items: dropdownItems}}
                        trigger={['click']}
                    >
                        <Space>
                            {username}
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </div>
            </div>
            <div id="main-section">
                <div id="sidebar">
                <nav>
                    <ul>
                        {menus.map((menu) => (
                            <li key={menu.id}>
                            <NavLink
                                to={`${menu.id}`}
                                className={({ isActive, isPending }) =>
                                isActive
                                    ? "active"
                                    : isPending
                                    ? "pending"
                                    : ""
                                }
                            >
                                {menu.icon}{capitalizeFirst(menu.id)}
                            </NavLink>
                            </li>
                        ))}
                        <li key="divider"><Divider></Divider></li>
                        <li key="settings">
                            <NavLink
                                    to="settings"
                                    className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                        ? "pending"
                                        : ""
                                    }
                                >
                                <MdSettings/>Settings
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                </div>
                <div id="detail">
                    <Outlet />
                </div>
            </div>
        </>
    );
  }