import { DatePicker, Slider} from 'antd';
import PropTypes from 'prop-types';
import orderPropType from "../../proptypes/OrderPropType";
import StepDisplay from "../StepDisplay";
import { useState } from "react";
import dayjs from 'dayjs';

InitialPaymentStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
};

export default function InitialPaymentStep ({order, setOrder}) {
    let defaultInitialPayment = 30;
    const [initialPayment, setInitialPayment] = useState(order.initialPayment ?? defaultInitialPayment);
    const defaultInitialPaymentDate = dayjs.utc().format();
    const [initialPaymentDate, setInitialPaymentDate] = useState(order.initialPaymentDate ?? defaultInitialPaymentDate);
    const stepOrder = 0;
    const [edited, setEdited] = useState(false);
    const handleComplete = () => {
        setOrder({
            ...order, 
            initialPayment,
            initialPaymentDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };

    const handleUpdate = () => {
        setOrder({
            ...order, 
            initialPayment,
            initialPaymentDate,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    updatedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Complete initial payment"}
        description={"Introduce how much percentage you will pay as initial payment and the date when the initial payment was completed"}
        showSkipButton
        showContinueButton
        continueButtonText={"Payment completed"}
        onComplete={handleComplete}
        onUpdate={handleUpdate}
        edited={edited}
        onCancelEdition={() => {
            setInitialPayment(order.initialPayment ?? defaultInitialPayment);
            setInitialPaymentDate(order.initialPaymentDate ?? defaultInitialPaymentDate);
            setEdited(false);
        }}
        onSkip={handleComplete}
    >
        <div className="step-input">
            <span>Initial payment date</span>
            <DatePicker
                defaultValue={dayjs(initialPaymentDate)}
                value={dayjs(initialPaymentDate)}
                onChange={(date) => {
                    setEdited(true);
                    setInitialPaymentDate(date.utc().format());
                }} 
            />
        </div>
        <Slider 
            defaultValue={initialPayment}
            tooltip={{ open: true }}
            value={initialPayment}
            onChange={(value)=> {
                setEdited(true);
                setInitialPayment(value);
            }}
        />
    </StepDisplay>;
}