import '../../styles/orders.css';
import { useOrders } from '../../hooks/orders';
import { useState, useEffect } from 'react';
import { Button, Table, Skeleton, Popconfirm, notification } from 'antd';
import OrdersFilter from '../../components/OrdersFilter';
import { Link } from 'react-router-dom';
import CreateOrderModal from '../../components/CreateOrderModal';
import { deleteOrder } from '../../service/order';
import { getStateName } from '../../utils/order';
import ReactGA from 'react-ga';
import { ORDERS } from '../../utils/GoogleAnalyticsCategories';
import { OrderEvents } from '../../utils/GoogleAnalyticsEvents';

export default function Orders () {
    const [orders, setOrders] = useOrders();
    const [showingOrders, setShowingOrders] = useState(orders);
    const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setShowingOrders(orders);        
    }, [orders]);

    const handleOrderCreated = (order) => setOrders([...orders, order]);
    const handleDeleteOrder = (orderId) => {
        deleteOrder(orderId)
            .then(() => setOrders(orders.filter(order => order.orderId !== orderId)))
            .catch(() => api.error({
                message: "Error",
                description: "Could not delete the order. Please try later",
                placement: "topRight",
              }));
    };

    const handleCreateOrder = () => {
        setShowCreateOrderModal(true);
        ReactGA.event({
            category: ORDERS,
            action: OrderEvents.CLICK_CREATE_ORDER
        });
    };
    
    const handleViewDetails = () => {
        setShowCreateOrderModal(true);
        ReactGA.event({
            category: ORDERS,
            action: OrderEvents.VIEW_DETAILS
        });
    };



    const columns = [
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        },
        {
            title: 'State',
            key: 'state',
            align: 'center',
            render: (record) => <span>{getStateName(record)}</span>
        },
        {
            title: 'Units',
            dataIndex: 'unitsAmount',
            key: 'units',
            align: 'center',
        },
        {
            title: 'Destination country',
            key: 'destinationCountry',
            dataIndex: 'destinationCountry',
            align: 'center',
        },
        {
            title: 'Import type',
            key: 'importType',
            align: 'center',
            dataIndex: 'importType',
        },
        {
            title: '',
            key: 'viewDetails',
            align: 'center',
            render: (record) => {
                return <Link to={`/orders/${record.orderId}`}><Button onClick={handleViewDetails} className="amazops-button continue-button">View details</Button></Link>;
            },
        },
        {
            title: '',
            key: 'viewDetails',
            align: 'center',
            render: (record) => {
                return <Popconfirm
                    className='delete-order'
                    title="Delete the order"
                    description="Are you sure to delete this order?"
                    onConfirm={() => handleDeleteOrder(record.orderId)}
                    okButtonProps={{style: {backgroundColor: "var(--red)"}}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a href="#">Delete</a>
                  </Popconfirm>;
            },
        },
    ];

    return  <div id="orders">
            <h2>Orders</h2>
            {contextHolder}
            <OrdersFilter 
                orders={orders} 
                setShowingOrders={setShowingOrders}
                showSKUFilter
                showStateFilter
                showImportTypeFilter
                showDestinationCountryFilter/>
            <div id="new-order-button-container">
                {showingOrders ? 
                    <Button 
                        className="amazops-button success-button"
                        onClick={handleCreateOrder}>
                            Create New Order
                    </Button>
                    : <Skeleton.Button active/>
                }
            </div>
            {showingOrders ? 
                <div id="orders-table-container">
                    <h3>Orders</h3>
                    <Table columns={columns} dataSource={showingOrders} pagination={{pageSize: 5}} rowKey="orderId"/>
                </div>
                : <Skeleton active paragraph={{ rows: 18 }} />
            }
            <CreateOrderModal 
                showCreateOrderModal={showCreateOrderModal} 
                setShowCreateOrderModal={setShowCreateOrderModal}
                onOrderCreation={handleOrderCreated}
                />
        </div>;

}