import { Button, Input} from 'antd';
import orderPropType from '../../proptypes/OrderPropType';
import StepDisplay from "../StepDisplay";
import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';

ShareTrackingNumberWithTransportAgentStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
};

export default function ShareTrackingNumberWithTransportAgentStep ({order, setOrder}) {

    const [showCopiedConfirmationText, setShowCopiedConfirmationText] = useState(false);
    const stepOrder = 8;
    const handleComplete = () => {
        setOrder({
            ...order, 
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    const handleCopyTrackingNumber = () => {
        navigator.clipboard.writeText(order.transportAgentTrackingNumber);
        setShowCopiedConfirmationText(true);
        setTimeout(() => setShowCopiedConfirmationText(false), 3000);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Share tracking number with transport agent"}
        description={"Remember to share the tracking number with your transport agent so that they can receive the goods successfully. You can find the tracking number below:"}
        showSkipButton
        showContinueButton
        continueButtonText={"Complete"}
        onComplete={handleComplete}
        onSkip={handleComplete}
    >
        <div className="copy-tracking-number-container">
            <div className="step-input">
                <span>Tracking number</span>
                <Input disabled value={order.transportAgentTrackingNumber}></Input>
            </div>
            <Button 
                className="continue-button amazops-button"
                onClick={handleCopyTrackingNumber}>
                    Copy
            </Button>
        </div>
        {showCopiedConfirmationText && <span className='tracking-number-confirmation-text'>Tracking number copied! ✅</span>}
    </StepDisplay>;
}