import { capitalizeFirst } from "./utils";

export const cleanOrder = (order) => {
    return {
        ...order,
        destinationCountry: capitalizeFirst(order.destinationCountry),
        importType: capitalizeFirst(order.importType),
    };
};

export const getDefaultImportDaysByImportType = (importType) => {
    if (importType === 'Plane') return 15;
    if (importType === 'Ship') return 50;
    if (importType === 'Truck') return 45;
    if (importType === 'Train') return 50;
};

export const getStateName = (order) => {
    return order.stepsLogs[order.state].name;
};