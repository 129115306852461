import "../../styles/stepDisplay.css";
import { Alert, Input} from 'antd';
import StepDisplay from "../StepDisplay";
import orderPropType from "../../proptypes/OrderPropType";
import PropTypes from 'prop-types';
import { useState } from "react";
import dayjs from "dayjs";

AskForImportTrackingNumberStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
};

export default function AskForImportTrackingNumberStep ({order, setOrder}) {

    const stepOrder = 12;
    const [importTrackingNumber, setImportTrackingNumber] = useState(order.importTrackingNumber ?? "");
    const [edited, setEdited] = useState(false);
    const handleComplete = () => {
        if (importTrackingNumber) {
            setOrder({
                ...order, 
                importTrackingNumber,
                state: order.state > stepOrder ? order.state : stepOrder + 1,
                stepsLogs: {
                    ...order.stepsLogs,
                    [stepOrder]: {
                        ...order.stepsLogs[stepOrder],
                        completedAt: dayjs.utc().format()
                    }
                }
            });
            setEdited(false);
        }
    };

    const handleSkip = () => {
        setOrder({
            ...order, 
            importTrackingNumber,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
        setEdited(false);
    };
    
    const handleUpdate = () => {
        if (importTrackingNumber) {
            setOrder({
                ...order, 
                importTrackingNumber,
                state: order.state > stepOrder ? order.state : stepOrder + 1,
                stepsLogs: {
                    ...order.stepsLogs,
                    [stepOrder]: {
                        ...order.stepsLogs[stepOrder],
                        updatedAt: dayjs.utc().format()
                    }
                }
            });
            setEdited(false);
        }
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Ask for the import tracking number"}
        description={"Ask your transport agent to share with you the import tracking number so that you can add it on Amazon Seller Central. Save the import tracking number below:"}
        showSkipButton
        showContinueButton
        continueButtonText={"Confirm"}
        onComplete={handleComplete}
        onUpdate={handleUpdate}
        edited={edited}
        onCancelEdition={() => {
            setImportTrackingNumber(order.importTrackingNumber ?? "");
            setEdited(false);
        }}
        onSkip={handleSkip}
    >
        <p className="description">(If your provider is shipping the goods, then ask to your provider for this import tracking number)</p>
        <div className="step-input">
            <span>Tracking number</span>
            <Input
                value={importTrackingNumber}
                onChange={(event) => {
                    setImportTrackingNumber(event.target.value);
                    setEdited(true);
                }}
            />
        </div>
        {!importTrackingNumber && 
            <Alert
                description={"Tracking number cannot be empty"}
                type="error"
                showIcon
            />
        }
    </StepDisplay>;
}