import { Button, Input, Tooltip, notification, Divider } from "antd";
import "../../styles/settings.css";
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { MdVerified } from "react-icons/md";
import { CgDanger } from "react-icons/cg";
import Password from "antd/es/input/Password";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import { SETTINGS } from '../../utils/GoogleAnalyticsCategories';
import { SettingsEvents } from '../../utils/GoogleAnalyticsEvents';
import { createCustomerPortalSession } from "../../service/user";

export default function Settings () {

    const [email, setEmail] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
    const [updateEmailIsLoading, setUpdateEmailIsLoading] = useState(false);
    const [verifyEmailIsLoading, setVerifyEmailIsLoading] = useState(false);
    const [updatePasswordIsLoading, setUpdatePasswordIsLoading] = useState(false);
    const [showResendVerificationCodeButton, setShowResendVerificationCodeButton] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [createCustomerSessionPortalIsLoading, setCreateCustomerSessionPortalIsLoading] = useState(false);

    let initialEmail;

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setEmail(user.attributes.email);
                initialEmail = user.attributes.email;
                setIsEmailVerified(user.attributes.email_verified);
            })
            .catch(error => {
                console.log(error);
                api.error({
                    message: "Error",
                    description: "Could not retrieve user information. Please try later",
                    placement: "topRight",
                  });
            });
    }, []);

    const handleUpdateEmail = () => {
        if (initialEmail && initialEmail !== email) {
            setUpdateEmailIsLoading(true);
            Auth.currentAuthenticatedUser()
            .then(user => Auth.updateUserAttributes(user, {'email': email}))
            .then(() => setUpdateEmailIsLoading(false))
            .then(() => setShowVerificationCodeInput(true))
            .catch(error => {
                console.log(error);    
                setUpdateEmailIsLoading(false);
                api.error({
                    message: "Error",
                    description: "Could not update the email. Please try later",
                    placement: "topRight",
                  });
            });
        }  
    };

    const handleVerifyEmail = () => {
        if (verificationCode) {
            setVerifyEmailIsLoading(true);
            Auth.verifyCurrentUserAttributeSubmit('email', verificationCode)
            .then(() => Auth.currentAuthenticatedUser({bypassCache: true}))
            .then(user => setIsEmailVerified(user.attributes.email_verified))
            .then(() => setVerifyEmailIsLoading(false))
            .then(() => setShowVerificationCodeInput(false))
            .then(() => ReactGA.event({
                category: SETTINGS,
                action: SettingsEvents.EMAIL_UPDATED
            }))
            .catch(error => {
                    console.log(error);
                    setVerifyEmailIsLoading(false);
                    setShowResendVerificationCodeButton(true);
                    api.error({
                        message: "Error",
                        description: "Could not verify code. Please try resending another code",
                        placement: "topRight",
                      });
                });
        }
    };
    
    const handleResendCode = () => {
        Auth.verifyCurrentUserAttribute("email")
            .catch(error => {
                console.log(error);
                api.error({
                    message: "Error",
                    description: "Could not resend code. Please try later",
                    placement: "topRight",
                  });
            });
    };

    const handlePasswordUpdate = () => {
        setUpdatePasswordIsLoading(true);
            Auth.currentAuthenticatedUser()
                .then((user) => Auth.changePassword(user, oldPassword, newPassword))
                .then(() => setUpdatePasswordIsLoading(false))
                .then(() => ReactGA.event({
                    category: SETTINGS,
                    action: SettingsEvents.PASSWORD_UPDATED
                }))
                .catch((error) => {
                    console.log(error);
                    setUpdatePasswordIsLoading(false);
                    api.error({
                        message: "Error",
                        description: "Could not update the password. Please try later",
                        placement: "topRight",
                      });
                });
    };

    const handleManageSubscriptionClick = async () => {
        setCreateCustomerSessionPortalIsLoading(true);
        createCustomerPortalSession()
            .then(session => {
                setCreateCustomerSessionPortalIsLoading(false);
                window.open(session.data.customerPortalURL, "_blank");
            })
            .catch(error => {
                console.log(error);
                setCreateCustomerSessionPortalIsLoading(false);
                api.error({
                    message: "Error",
                    description: "Could not create subscription management link. Please try later",
                    placement: "topRight",
                  });
            });
    };

    return  <div id="settings">
        {contextHolder}
        <h2>Settings</h2>
        <div id="settings-section">
            <div className="user-settings">
                <div className="amazops-input">
                    <h3>Email</h3>
                    <Input
                        value={email}
                        addonAfter={isEmailVerified ? 
                            <Tooltip className="email-verified-icon" title={"Email verified"} arrow>
                                <MdVerified />
                            </Tooltip>
                            : <Tooltip className="email-not-verified-icon" title={"Email not verified"} arrow>
                                <CgDanger />
                            </Tooltip>
                        }
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <div className="update-setting-button-container">
                        <Button className="amazops-button continue-button" onClick={handleUpdateEmail} loading={updateEmailIsLoading}>Update email</Button>
                    </div>
                </div>
                {showVerificationCodeInput &&
                    <div className="amazops-input">
                        <h3>Verification code</h3>
                        <span>Introduce the verification code</span>
                        <span>we have just sent to your new mail</span>
                        <Input
                            value={verificationCode}
                            onChange={(event) => setVerificationCode(event.target.value)}
                        />
                        <div className="update-setting-button-container">
                            {showResendVerificationCodeButton && 
                                <Button 
                                    className="amazops-button skip-button"
                                    onClick={handleResendCode}
                                    loading={verifyEmailIsLoading}>
                                        Resend code
                                </Button>}
                            <Button className="amazops-button continue-button" onClick={handleVerifyEmail} loading={verifyEmailIsLoading}>Verify</Button>
                        </div>
                    </div>
                }
                <div className="amazops-input">
                    <h3>Password</h3>
                    <span>Old password</span>
                    <Password
                        value={oldPassword}
                        onChange={(event) => setOldPassword(event.target.value)}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <span>New password</span>
                    <Password
                        value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                    />
                    <div className="update-setting-button-container">
                        <Button className="amazops-button continue-button" onClick={handlePasswordUpdate} loading={updatePasswordIsLoading}>Update password</Button>
                    </div>
                </div>
            </div>
            <Divider type="vertical"/>
            <div className="subscription-settings">
                <h3>Subscription</h3>
                <Button 
                    className="amazops-button continue-button" 
                    onClick={handleManageSubscriptionClick}
                    loading={createCustomerSessionPortalIsLoading}
                    >Manage subscription
                </Button>
            </div>
        </div>
    </div>;
}