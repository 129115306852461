import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import "../styles/billing.css";
import HorizontalLogo from "../logo-horizontal.png";
import { RouterProvider } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { querySubscription } from '../service/subscription';
import { Button } from 'antd';
import { queryUserMetadata, updateUserMetadata } from '../service/user';
import { getFBACountries } from '../service/FBACountries';
import { FBACountriesContext } from '../context/FBACountriesContext';
import { getCookie } from '../utils/utils';

// eslint-disable-next-line react/prop-types
export default function Billing ({router}) {

    const [userId, setUserId] = useState("");
    const [subscription, setSubscription] = useState();
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [userHasUsedFreeTrial, setUserHasUsedFreeTrial] = useState(false);
    const [fbaCountries, setFBACountries] = useState([]);
    
    useEffect(() => {
        getFBACountries()
            .then(response => setFBACountries(response.data))
            .catch(error => console.error("Could not retrieve FBA countries", error.message));
    }, []);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => setUserId(result.attributes.sub));

        querySubscription()
            .then(response => {
                setSubscription(response.data);
                setLoadingSubscription(false);
            })
            .catch(() => setLoadingSubscription(false));

        queryUserMetadata()
            .then(response => {
                setUserHasUsedFreeTrial(response.data.hasUsedFreeTrial);
                if (!response.data.referral) {
                    const referral = getCookie('referral');
                    if (referral) updateUserMetadata('referral', referral);
                    else updateUserMetadata('referral', 'amazops');
                }
            })
            .catch(error => {
                console.error("Could not retrieve user metadata", error.message);
                const referral = getCookie('referral');
                if (referral) updateUserMetadata('referral', referral);
                else updateUserMetadata('referral', 'amazops');
            });
    }, []);

    return !loadingSubscription ?  
            subscription && subscription.plan.active ? 
                <FBACountriesContext.Provider value={fbaCountries}>
                    <RouterProvider router={router} />
                </FBACountriesContext.Provider>
                : <div id="billing">
                    <img src={HorizontalLogo}></img>
                    <Button type="text" onClick={() => Auth.signOut()}>Log out</Button>
                    <div className='pricing-table-wrapper'>
                        <h1>Pricing</h1>
                        <div className='princing-description'>
                            <p>In order to use Amazops <strong>you need to confirm the subscription.</strong></p>
                            {!userHasUsedFreeTrial && <p>However, <strong>you will not be be charged</strong> until the end of the 30-days free trial period.</p>}
                            <p><strong>You can cancel the subscription at any point</strong></p>
                        </div>
                        <stripe-pricing-table
                            pricing-table-id={userHasUsedFreeTrial ? 
                                process.env.REACT_APP_PRICING_TABLE_ID 
                                : process.env.REACT_APP_PRICING_TABLE_ID_WITH_FREE_TRIAL}
                            publishable-key={process.env.REACT_APP_PUBLISHABLE_KEY}
                            client-reference-id={userId}>
                        </stripe-pricing-table>
                    </div>
                    <div className='decorative-orange-bar'></div>
                </div>
        : <LoadingScreen/>;   
}