import '../styles/orderSummary.css';
import { Button, Descriptions, Popconfirm, notification } from 'antd';
import PropTypes from 'prop-types';
import { deleteOrder } from '../service/order';
import { useNavigate } from "react-router-dom";
import { getStateName } from '../utils/order';
import { useState } from 'react';

OrderSummary.propTypes = {
    order: PropTypes.shape({
        sku: PropTypes.string.isRequired,
        state: PropTypes.number.isRequired,
        unitsAmount: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        destinationCountry: PropTypes.string.isRequired,
        importType: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        stepsLogs: PropTypes.shape(),
    }).isRequired,
    setShowEditOrderModal: PropTypes.func.isRequired
};


export default function OrderSummary ({order, setShowEditOrderModal}) {

    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const handleDeleteOrder = () => {
        setDeleteInProgress(true);
        deleteOrder(order.orderId)
            .then(() => {
                setDeleteInProgress(false);
                navigate("/orders");
            })
            .catch((error) => {
                setDeleteInProgress(false);
                console.log(error);
                api.error({
                    message: "Error",
                    description: "Could not delete the order. Please try later",
                    placement: "topRight",
                  });
            });
    };
    return <div id="order-summary">
        {contextHolder}
        <div id="order-summary-header">
            <h3>Order summary</h3>
            <div>
                <Button className="amazops-button skip-button" onClick={() => setShowEditOrderModal(true)}>Edit</Button>
                <Popconfirm
                    title="Delete the order"
                    description="Are you sure to delete this order?"
                    onConfirm={() => handleDeleteOrder(order.orderId)}
                    okButtonProps={{style: {backgroundColor: "var(--red)"}}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="amazops-button delete-button" loading={deleteInProgress}>Delete</Button>
                  </Popconfirm>
            </div>
        </div>
        <Descriptions>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="SKU">{order.sku}</Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="Unit">{order.unitsAmount}</Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="Destination country">{order.destinationCountry}</Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="Created at">{order.createdAt}</Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="Current state">{getStateName(order)}</Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="Import type">{order.importType}</Descriptions.Item>
            <Descriptions.Item labelStyle={{fontWeight: 700, color: "black"}} label="Order ID">{order.orderId}</Descriptions.Item>
        </Descriptions>
    </div>;
}