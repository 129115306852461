import { Progress } from 'antd';
import StepDisplay from "../StepDisplay";
import PropTypes from 'prop-types';
import orderPropType from "../../proptypes/OrderPropType";
import dayjs from 'dayjs';

WaitProductionCompletionStep.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
};

export default function WaitProductionCompletionStep ({order, setOrder}) {
    const stepOrder = 4;
    const productionDeadline = order.productionDeadline;
    const startDate = dayjs(order.productionStartDate).format("DD/MM/YYYY");
    const endDate = dayjs(order.productionStartDate).add(productionDeadline, 'day').format("DD/MM/YYYY");
    const calculatedPercentage = dayjs().diff(dayjs(order.productionStartDate), 'days') * 100 / productionDeadline;
    const displayPercentage = Math.ceil(calculatedPercentage) > 100 ? 100 : Math.ceil(calculatedPercentage) < 0 ? 0 : Math.ceil(calculatedPercentage);
    const handleComplete = () => {
        setOrder({
            ...order,
            state: order.state > stepOrder ? order.state : stepOrder + 1,
            stepsLogs: {
                ...order.stepsLogs,
                [stepOrder]: {
                    ...order.stepsLogs[stepOrder],
                    completedAt: dayjs.utc().format()
                }
            }
        });
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Wait for production completion"}
        description={"Time to wait until the production has completed. Maybe look for some other product to sell?"}
        showSkipButton
        showContinueButton={displayPercentage === 100}
        onComplete={handleComplete}
        continueButtonText={"Complete"}
        onSkip={handleComplete}
    >
        <Progress className="step-progress" percent={displayPercentage} />
        <div className="progress-dates">
            <span>{startDate}</span>
            <span>{endDate}</span>
        </div>
    </StepDisplay>;
}