import '../../styles/order.css';
import { useParams } from 'react-router-dom';
import { useOrder } from '../../hooks/orders';
import OrderSummary from '../../components/OrderSummary';
import { Skeleton, notification } from 'antd';
import InitialPaymentStep from '../../components/steps/InitialPaymentStep';
import CreateSKULabelsStep from '../../components/steps/CreateSKULabelsStep';
import ShareSKULabelsStep from '../../components/steps/ShareSKULabelsStep';
import ProductionInformationStep from '../../components/steps/ProductionInformationStep';
import WaitProductionCompletionStep from '../../components/steps/WaitProductionCompletionStep';
import AskForBoxesInformation from '../../components/steps/AskForBoxesInformation';
import BalancePaymentStep from '../../components/steps/BalancePaymentStep';
import SendGoodsToTransportAgentStep from '../../components/steps/SendGoodsToTransportAgentStep';
import ShareTrackingNumberWithTransportAgentStep from '../../components/steps/ShareTrackingNumberWithTransportAgentStep';
import CreateShippingPlanStep from '../../components/steps/CreateShippingPlanStep';
import CompleteTransportPaymentStep from '../../components/steps/CompleteTransportPaymentStep';
import AddTrackingNumberToAmazonSellerCentral from '../../components/steps/AddTrackingNumberToAmazonSellerCentral';
import WaitForShipment from '../../components/steps/WaitForShipment';
import CompletedStep from '../../components/steps/CompletedStep';
import { useEffect, useState } from 'react';
import { updateOrder } from '../../service/order';
import ShareShippingLabelsWithTransportAgentStep from '../../components/steps/ShareShippingLabelsWithTransportAgent';
import AskForImportTrackingNumberStep from '../../components/steps/AskForImportTrackingNumberStep';
import EditOrderModal from '../../components/EditOrderModal';

export default function Order () {
    const {orderId} = useParams();
    const [order, setOrder] = useOrder(orderId);
    const [showEditOrderModal, setShowEditOrderModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (order) {
            updateOrder(order)
                .catch((error) => {
                    console.error(error);
                    api.error({
                        message: "Error",
                        description: "Could not edit the order. Please try later",
                        placement: "topRight",
                    });
                });
        }
    }, [order]);

    useEffect(() => {
        if (order) {
            const step = document.querySelector( `#step-${order.state}` );
            step && step.scrollIntoView( { behavior: 'smooth', block: 'center' } );
        }
    }, [order]);

    const handleEditOrder = (order) => {
        setOrder(order);
    };

    const renderSteps = () => {
        return <>
            <InitialPaymentStep order={order} setOrder={setOrder}/>
            <CreateSKULabelsStep order={order} setOrder={setOrder}/>
            <ShareSKULabelsStep order={order} setOrder={setOrder}/>
            <ProductionInformationStep order={order} setOrder={setOrder}/>
            <WaitProductionCompletionStep order={order} setOrder={setOrder}/>
            <AskForBoxesInformation order={order} setOrder={setOrder}/>
            <BalancePaymentStep order={order} setOrder={setOrder}/>
            <SendGoodsToTransportAgentStep order={order} setOrder={setOrder}/>
            <ShareTrackingNumberWithTransportAgentStep order={order} setOrder={setOrder}/>
            <CreateShippingPlanStep order={order} setOrder={setOrder}/>
            <ShareShippingLabelsWithTransportAgentStep order={order} setOrder={setOrder}/>
            <CompleteTransportPaymentStep order={order} setOrder={setOrder}/>
            <AskForImportTrackingNumberStep order={order} setOrder={setOrder}/>
            <AddTrackingNumberToAmazonSellerCentral order={order} setOrder={setOrder}/>
            <WaitForShipment order={order} setOrder={setOrder}/>
            <CompletedStep order={order}></CompletedStep>
        </>;
    };

    return <div id="order">
        {contextHolder}
        {order ? 
            <OrderSummary order={order} setShowEditOrderModal={setShowEditOrderModal}/>
            : <Skeleton active paragraph={{ rows: 2 }} />
        }
        {order ? 
            renderSteps()
            : <Skeleton active paragraph={{ rows: 18 }} />
        }
        {order && 
            <EditOrderModal 
                order={order}
                showEditOrderModal={showEditOrderModal}
                setShowEditOrderModal={setShowEditOrderModal}
                onOrderEdit={handleEditOrder}

            />
        }
    </div>;
}