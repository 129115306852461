import '../../styles/imports.css';
import { useOrders } from '../../hooks/orders';
import { useEffect, useState } from 'react';
import OrdersFilter from '../../components/OrdersFilter';
import ImportDisplay from '../../components/ImportDisplay';
import { Link } from 'react-router-dom';
import { Button, Empty, Skeleton } from 'antd';
import ReactGA from 'react-ga';
import { IMPORTS } from '../../utils/GoogleAnalyticsCategories';
import { ImportEvents } from '../../utils/GoogleAnalyticsEvents';

export default function Imports () {
    // eslint-disable-next-line no-unused-vars
    const [orders, setOrders] = useOrders();
    const [showingOrders, setShowingOrders] = useState(orders);
    
    useEffect(() => {
        if (orders) {
            const importingOrders = orders.filter(order => order.state === 14);
            setShowingOrders(importingOrders);
            console.log("importing orders", importingOrders);
        }
    }, [orders]);

    const handleClickOnImport = () => {
        ReactGA.event({
            category: IMPORTS,
            action: ImportEvents.VIEW_DETAILS
        });
    };

    return  <div id="imports">
            <h2>Imports</h2>
            <OrdersFilter 
                orders={showingOrders} 
                setShowingOrders={setShowingOrders}
                showSKUFilter
                showImportTypeFilter
                showDestinationCountryFilter/>
            {
                showingOrders ?
                    showingOrders.length === 0 ?
                        <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="There are no orders that are currently in transit">
                            <Link to="/orders"><Button className='amazops-button continue-button'>Go to orders page</Button></Link>
                        </Empty>
                        : showingOrders.map((order) => <Link className={"import"} onClick={handleClickOnImport} key={order.orderId} to={`/orders/${order.orderId}`}><ImportDisplay order={order}/></Link>) 
                    : <Skeleton active paragraph={{ rows: 18 }} />
            }
        </div>;

}