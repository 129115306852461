import "../styles/ordersFilter.css";
import { Select, Space, AutoComplete, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import orderPropType from "../proptypes/OrderPropType";

OrdersFilter.propTypes = {
    orders: PropTypes.arrayOf(orderPropType),
    setShowingOrders: PropTypes.func.isRequired,
    showSKUFilter: PropTypes.bool,
    showImportTypeFilter: PropTypes.bool,
    showDestinationCountryFilter: PropTypes.bool,
    showStateFilter: PropTypes.bool,
};

export default function OrdersFilter ({orders, setShowingOrders, showSKUFilter, showImportTypeFilter, showDestinationCountryFilter, showStateFilter}) {
    
    const [SKUOptions, setSKUOptions] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({
        sku: "All",
        importType: "All",
        destinationCountry: "All",
        state: "All"
    });

    useEffect(() => {
        if (orders) {
            const filteredOrders = orders
                .filter(o => appliedFilters.sku === "All" ? true : o.sku.includes(appliedFilters.sku))
                .filter(o => appliedFilters.importType === "All" ? true : o.importType === appliedFilters.importType)
                .filter(o => appliedFilters.destinationCountry === "All" ? true : o.destinationCountry === appliedFilters.destinationCountry)
                .filter(o => appliedFilters.state === "All" ? true : o.state === appliedFilters.state);
            setShowingOrders(filteredOrders);
        }
    }, [appliedFilters]);

    const getUniqueStates = (orders) => {
        return [...new Set(orders.map(order => order.stepsLogs[order.state].name))];
    };

    const getUniqueValues = (orders, attribute) => {
        return [...new Set(orders.map(order => order[attribute]))];
    };

    const getAllSKUOptions = () => {
        return [
            {value: "All"},
            ...getUniqueValues(orders, "sku").map(sku => ({value: sku}))
        ];
    };

    const getSimilarOptions = (searchText) => {
        return getAllSKUOptions().filter(option => option.value.includes(searchText));
    };

    return orders ? 
        <div id="orders-filter">
            <h3>Filters</h3>
            <Space wrap id="filters">
                {showSKUFilter && 
                    <div className="filter-input">
                        <span>SKU</span>
                        <AutoComplete
                            options={SKUOptions}
                            style={{ width: 200 }}
                            onSelect={(value) => setAppliedFilters({...appliedFilters, sku: value})}
                            onSearch={(text) => setSKUOptions(getSimilarOptions(text))}
                            placeholder="Enter an SKU"
                        />
                    </div>
                }
                {showImportTypeFilter &&
                    <div className="filter-input">
                        <span>Import type</span>
                        <Select
                            defaultValue="All"
                            style={{ width: 120 }}
                            onChange={(value) => setAppliedFilters({...appliedFilters, importType: value})}
                            options={[
                                { value: 'All', label: 'All' },
                                ...getUniqueValues(orders, "importType").map(importType => ({value: importType, label: importType}))
                            ]}
                        />
                    </div>
                }
                {showDestinationCountryFilter &&
                    <div className="filter-input">
                        <span>Destination country</span>
                        <Select
                            defaultValue="All"
                            style={{ width: 120 }}
                            onChange={(value) => setAppliedFilters({...appliedFilters, destinationCountry: value})}
                            options={[
                                { value: 'All', label: 'All' },
                                ...getUniqueValues(orders, "destinationCountry").map(country => ({value: country, label: country}))
                            ]}
                        />
                    </div>
                }
                {showStateFilter &&
                    <div className="filter-input">
                        <span>State</span>
                        <Select
                            defaultValue="All"
                            style={{ width: 200 }}
                            onChange={(value) => setAppliedFilters({...appliedFilters, state: value})}
                            options={[
                                { value: 'All', label: 'All' },
                                ...getUniqueStates(orders).map(state => ({value: state, label: state}))
                            ]}
                        />
                    </div>
                }
            </Space>
        </div>   
        : <Skeleton active paragraph={{ rows: 2 }} />;
}
