import { Alert, Button } from 'antd';
import BoxInformation from "../BoxInformation";
import { useState } from "react";
import StepDisplay from "../StepDisplay";
import PropTypes from 'prop-types';
import orderPropType from "../../proptypes/OrderPropType";
import {v4} from 'uuid';
import dayjs from 'dayjs';

AskForBoxesInformation.propTypes = {
    order: orderPropType.isRequired,
    setOrder: PropTypes.func.isRequired,
};

export default function AskForBoxesInformation ({order, setOrder}) {
    
    const stepOrder = 5;
    const createNewShippingBox = () => {
        return {
            weight: 0,
            height: 0,
            width: 0,
            depth: 0,
            numberOfBoxes: 1,
            units: 0,
            id: v4(),
            error: undefined
        };
    };
    const defaultShippingBoxes = [createNewShippingBox()];
    const [shippingBoxes, setShippingBoxes] = useState(order.shippingBoxes ?? defaultShippingBoxes);
    const [edited, setEdited] = useState(false);
    const [boxesInformationError, setBoxesInformationError] = useState("");
    
    const handleAddBox = () => {
        setShippingBoxes([...shippingBoxes, createNewShippingBox()]);
        setEdited(true);
    };
    const handleComplete = () => {
        const boxesError = getBoxesError();
        if (boxesError) {
            setBoxesInformationError(boxesError);
        } else {
            setOrder({
                ...order, 
                shippingBoxes,
                state: order.state > stepOrder ? order.state : stepOrder + 1,
                stepsLogs: {
                    ...order.stepsLogs,
                    [stepOrder]: {
                        ...order.stepsLogs[stepOrder],
                        completedAt: dayjs.utc().format()
                    }
                }
            });
            setEdited(false);
        }
        
    };

    const getBoxesError = () => {
        const selectedUnits = shippingBoxes.reduce((accumulator, box) => accumulator + box.numberOfBoxes * box.units, 0);
        if (selectedUnits !== order.unitsAmount) return `It was expected ${order.unitsAmount} units but you have introduced ${selectedUnits} units`;
    };

    const handleUpdate = () => {
        const boxesError = getBoxesError();
        if (boxesError) {
            setBoxesInformationError(boxesError);
        } else {
            setOrder({
                ...order, 
                shippingBoxes,
                state: order.state > stepOrder ? order.state : stepOrder + 1,
                stepsLogs: {
                    ...order.stepsLogs,
                    [stepOrder]: {
                        ...order.stepsLogs[stepOrder],
                        updatedAt: dayjs.utc().format()
                    }
                }
            });
            setEdited(false);
        }
    };
    
    const handleRemoveBox = (deletedBoxId) => {
        setShippingBoxes(shippingBoxes.filter((box) => box.id !== deletedBoxId));
        setEdited(true);
    };

    const handleUpdateBoxInformation = (updatedBoxInformation) => {
        const updatedShippingBoxes = [];
        shippingBoxes.forEach(shippingBox => {
            if (shippingBox.id !== updatedBoxInformation.id) {
                updatedShippingBoxes.push(shippingBox);
            } else {
                updatedShippingBoxes.push(updatedBoxInformation);
            }
        });
        setShippingBoxes(updatedShippingBoxes);
        setEdited(true);
    };

    return <StepDisplay
        stepOrder={stepOrder}
        order={order}
        title={"Boxes information"}
        description={"Ask your provider for the number of boxes they will need to ship the goods, how many units there will be on each box and their weights and dimensions"}
        showSkipButton
        showContinueButton
        continueButtonText={"Confirm"}
        onComplete={handleComplete}
        onUpdate={handleUpdate}
        edited={edited}
        onCancelEdition={() => {
            setShippingBoxes(order.shippingBoxes ?? defaultShippingBoxes);
            setEdited(false);
        }}
        onSkip={handleComplete}
    >
        {shippingBoxes.map((boxInformation, index) => {
            return <BoxInformation 
                key={boxInformation.id}
                boxNumber={index}
                canDelete 
                onRemove={() => handleRemoveBox(boxInformation.id)}
                onChange={(updatedBoxInformation) => handleUpdateBoxInformation(updatedBoxInformation)}
                initialBoxInformation={boxInformation}/>;
        })}
        {boxesInformationError && 
            <Alert
                description={boxesInformationError}
                type="error"
                showIcon
            />
        }
        <div className="add-box-button-container">
            <Button 
                className="success-button amazops-button" 
                onClick={handleAddBox}>
                    Add box
            </Button>
        </div>
    </StepDisplay>;
}